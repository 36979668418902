@font-face {
    font-family: 'text-security-disc';
    src: url('../assets/fonts/text-security-disc.eot');
    src: url('../assets/fonts/text-security-disc.eot') format('embedded-opentype'),
        url('../assets/fonts/text-security-disc.woff') format('woff'),
        url('../assets/fonts/text-security-disc.woff2') format('woff2'),
        url('../assets/fonts/text-security-disc.ttf') format('truetype'),
        url('../assets/fonts/text-security-disc.svg') format('svg');
}

@font-face {
    font-family: 'Roboto Mono';
    src: url('../assets/fonts/roboto-mono-regular.ttf');
    src: url('../assets/fonts/roboto-mono-regular.ttf') format('truetype'),
        url('../assets/fonts/roboto-mono-regular.woff') format('woff'),
        url('../assets/fonts/roboto-mono-regular.woff2') format('woff2'),
}